import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Typed from 'react-typed';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Components
import Layout from '../components/layout/Layout';
import WebsitesSimulatorSection from '../components/section/simulator/Websites';
import WebsitesPlansSection from '../components/section/plans/Websites';
import RealisationsSection from '../components/section/Realisations';
import TestimonialsSection from '../components/section/Testimonials';
import ToolsSection from '../components/section/Tools';

const ResumePage = ({ data }) => {

  const { t } = useTranslation();

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div>
        <div className="gradient-y-gray position-relative" data-aos="fade-down">
          <div className="content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-xl-6">
                  <div className="mb-5">
                    <h1 className="display-4 mb-3">{t('title')}
                      <br></br>
                      <span className="text-primary text-highlight-behind-warning">
                        <Typed
                          strings={t('titleTyped').split(',')}
                          typeSpeed={40}
                          backSpeed={50}
                          loop >
                        </Typed>
                      </span>
                    </h1>
                    <p className="lead">{t('subtitle')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="devices-top-start-50 devices-rotated zi-2" style={{ width: '47rem' }}>
              <div className="devices-wrap devices-rotated-body">
                <figure className="device-tablet" style={{ width: '27rem' }}>
                  <div className="device-tablet-frame">
                    <StaticImage className="device-tablet-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-1.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </figure>
                <figure className="device-vertical-tablet" style={{ width: '18rem' }}>
                  <div className="device-vertical-tablet-frame">
                    <StaticImage className="device-vertical-tablet-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-2.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </figure>
                <figure className="device-mobile align-self-start" style={{ width: '10rem' }}>
                  <div className="device-mobile-frame">
                    <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-3.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </figure>
                <figure className="device-browser" style={{ width: '35rem' }}>
                  <div className="device-browser-header">
                    <div className="device-browser-header-btn-list">
                      <span className="device-browser-header-btn-list-btn"></span>
                      <span className="device-browser-header-btn-list-btn"></span>
                      <span className="device-browser-header-btn-list-btn"></span>
                    </div>
                    <div className="device-browser-header-browser-bar">https://lasmallagency.com</div>
                  </div>
                  <div className="device-browser-frame">
                    <StaticImage className="device-browser-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-4.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div className="shape shape-bottom zi-1" style={{ marginBottom: '-.125rem' }}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
            </svg>
          </div>
        </div>
        <div className="overflow-hidden" id="checklist">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title') }}></h2>
            </div>
            <div className="w-md-100 mx-md-auto">
              <div className="row justify-content-lg-center align-items-md-center">
                <div className="col-12 col-md-6 mb-md-0">
                  <div className="row justify-content-lg-center align-items-md-center">
                    <div className="col-6">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.summary')}</li>
                        <li className="list-checked-item">{t('checklist.works')}</li>
                        <li className="list-checked-item">{t('checklist.educations')}</li>
                        <li className="list-checked-item">{t('checklist.volunteers')}</li>
                        <li className="list-checked-item">{t('checklist.skills')}</li>
                        <li className="list-checked-item">{t('checklist.languages')}</li>
                        <li className="list-checked-item">{t('checklist.interests')}</li>
                        <li className="list-checked-item">{t('checklist.publications')}</li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.certifications')}</li>
                        <li className="list-checked-item">{t('checklist.courses')}</li>
                        <li className="list-checked-item">{t('checklist.projects')}</li>
                        <li className="list-checked-item">{t('checklist.awards')}</li>
                        <li className="list-checked-item">{t('checklist.causes')}</li>
                        <li className="list-checked-item">{t('checklist.portfolios')}</li>
                        <li className="list-checked-item">{t('checklist.contacts')}</li>
                        <li className="list-checked-item">{t('checklist.links')}</li>
                      </ul>
                    </div>
                  </div>
                  <AnchorLink className="link" href="#plans">{t('cta.ourPlans')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                </div>
                <div className="col-12 col-md-6">
                  <div className="position-relative">
                    <div className="card">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M11 11H13C13.6 11 14 11.4 14 12V21H10V12C10 11.4 10.4 11 11 11ZM16 3V21H20V3C20 2.4 19.6 2 19 2H17C16.4 2 16 2.4 16 3Z" fill="#035A4B"></path>
                            <path d="M21 20H8V16C8 15.4 7.6 15 7 15H5C4.4 15 4 15.4 4 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="#035A4B"></path>
                          </svg>
                        </span>
                        <h3 className="card-title">{t('checklist.card.title')}</h3>
                        <p className="card-text">{t('checklist.card.subtitle')}</p>
                      </div>
                    </div>
                    <figure className="position-absolute bottom-0 end-0 zi-n1 mb-n7 me-n7" style={{ width: '12rem' }}>
                      <StaticImage className="img-fluid" quality="30" src="../images/svg/components/dots-lg.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title') }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title')}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-design" id="process-design-tab" data-bs-toggle="tab" data-bs-target="#process-design" role="tab" aria-controls="process-design" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M6.45801 14.775L9.22501 17.542C11.1559 16.3304 12.9585 14.9255 14.605 13.349L10.651 9.39502C9.07452 11.0415 7.66962 12.8441 6.45801 14.775V14.775Z" fill="#035A4B"></path>
                          <path d="M9.19301 17.51C9.03401 19.936 6.76701 21.196 3.55701 21.935C3.34699 21.9838 3.12802 21.9782 2.92074 21.9189C2.71346 21.8596 2.52471 21.7484 2.37231 21.5959C2.2199 21.4434 2.10886 21.2545 2.04967 21.0472C1.99048 20.8399 1.98509 20.6209 2.034 20.411C2.772 17.201 4.03401 14.934 6.45801 14.775L9.19301 17.51ZM21.768 4.43697C21.9476 4.13006 22.0204 3.77232 21.9751 3.41963C21.9297 3.06694 21.7687 2.73919 21.5172 2.48775C21.2658 2.2363 20.9381 2.07524 20.5854 2.02986C20.2327 1.98449 19.8749 2.0574 19.568 2.23701C16.2817 4.20292 13.2827 6.61333 10.656 9.39998L14.61 13.354C17.395 10.7252 19.8037 7.72455 21.768 4.43697V4.43697Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.design.title')}</div>
                        <p className="text-body mb-0">{t('process.design.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-build" id="process-build-tab" data-bs-toggle="tab" data-bs-target="#process-build" role="tab" aria-controls="process-build" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2718 8.68537C16.8933 8.28319 16.9125 7.65032 17.3146 7.2718C17.7168 6.89329 18.3497 6.91246 18.7282 7.31464L22.7282 11.5646C23.0906 11.9497 23.0906 12.5503 22.7282 12.9354L18.7282 17.1854C18.3497 17.5875 17.7168 17.6067 17.3146 17.2282C16.9125 16.8497 16.8933 16.2168 17.2718 15.8146L20.6268 12.25L17.2718 8.68537Z" fill="#035A4B"></path>
                          <path d="M6.7282 8.68537C7.10671 8.28319 7.08754 7.65032 6.68536 7.2718C6.28319 6.89329 5.65031 6.91246 5.2718 7.31464L1.2718 11.5646C0.909397 11.9497 0.909397 12.5503 1.2718 12.9354L5.2718 17.1854C5.65031 17.5875 6.28319 17.6067 6.68536 17.2282C7.08754 16.8497 7.10671 16.2168 6.7282 15.8146L3.37325 12.25L6.7282 8.68537Z" fill="#035A4B"></path>
                          <rect opacity="0.3" x="12.7388" y="3.97168" width="2" height="16" rx="1" transform="rotate(12.3829 12.7388 3.97168)" fill="#035A4B"></rect>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.build.title')}</div>
                        <p className="text-body mb-0">{t('process.build.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-launch" id="process-launch-tab" data-bs-toggle="tab" data-bs-target="#process-launch" role="tab" aria-controls="process-launch" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="#035A4B"></path>
                          <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982V15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442V19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.publish.title')}</div>
                        <p className="text-body mb-0">{t('process.publish.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-follow-up" id="process-follow-up-tab" data-bs-toggle="tab" data-bs-target="#process-follow-up" role="tab" aria-controls="process-follow-up" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.followUp.title')}</div>
                        <p className="text-body mb-0">{t('process.followUp.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-process-conversation.svg" alt={t('process.conversation.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.conversation.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-design" role="tabpanel" aria-labelledby="process-design-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-process-design.svg" alt={t('process.design.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.design.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-build" role="tabpanel" aria-labelledby="process-build-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-process-build.svg" alt={t('process.build.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.build.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-launch" role="tabpanel" aria-labelledby="process-launch-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-process-launch.svg" alt={t('process.publish.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.publish.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-follow-up" role="tabpanel" aria-labelledby="process-follow-up-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/resume/la-small-agency-resume-process-follow-up.svg" alt={t('process.followUp.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.followUp.text')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="container content-space-2" data-aos="fade-up" data-aos-delay="300">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <span className="text-cap">{t('technologies.title')}</span>
            <h2>{t('technologies.subtitle')}</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
              <a className="card card-lg card-transition h-100 text-center" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                <div className="card-body">
                  <div className="mb-4">
                    <StaticImage className="avatar" quality="30" src="../images/brands/react-icon.svg" alt="React" />
                  </div>
                  <h3 className="card-title">{t('technologies.react.title')}</h3>
                  <p className="card-text text-body">{t('technologies.react.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a className="card card-lg card-transition h-100 text-center" href="https://nextjs.org/" target="_blank" rel="noopener noreferrer">
                <div className="card-body">
                  <div className="mb-4">
                    <StaticImage className="avatar" quality="30" src="../images/brands/next-js-icon.svg" alt="Next.js" />
                  </div>
                  <h3 className="card-title">{t('technologies.nextjs.title')}</h3>
                  <p className="card-text text-body">{t('technologies.nextjs.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
              <a className="card card-lg card-transition h-100 text-center" href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">
                <div className="card-body">
                  <div className="mb-4">
                    <StaticImage className="avatar" quality="30" src="../images/brands/gatsbyjs-icon.svg" alt="Gatsby" />
                  </div>
                  <h3 className="card-title">{t('technologies.gatsby.title')}</h3>
                  <p className="card-text text-body">{t('technologies.gatsby.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-light">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('simulator.title')}</h2>
              <p>{t('simulator.subtitle')}</p>
            </div>
            <WebsitesSimulatorSection data={data} plan={data.plan.frontmatter} service="resume" />
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('plans.title')}</h2>
            <p>{t('plans.subtitle')}</p>
          </div>
          <WebsitesPlansSection data={data} plan={data.plan.frontmatter} service="resume" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="home" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title')}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 text-center mx-md-auto mb-5">
            <span className="text-cap">{t('tools.title')}</span>
            <h2>{t('tools.subtitle')}</h2>
          </div>
          <ToolsSection data={data} service="resume" />
        </div>
        <div className="container content-space-2 content-space-b-lg-4">
          <div className="w-lg-75 mx-lg-auto">
            <div className="card card-sm overflow-hidden">
              <div className="card-body">
                <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                  <div className="col-md offset-md-3 mb-3 mb-md-0">
                    <h4 className="card-title">{t('cta.title')}</h4>
                  </div>
                  <div className="col-md-auto">
                    <Link className="btn btn-primary btn-transition" to="/contact/"><i className="bi bi-envelope me-1"></i>{t('cta.getStarted')}</Link>
                  </div>
                </div>
                <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                  <svg viewBox="0 0 451 902" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.125" d="M0 82C203.8 82 369 247.2 369 451C369 654.8 203.8 820 0 820" stroke="url(#paint2_linear)" strokeWidth="164" strokeMiterlimit="10"></path>
                    <defs>
                      <linearGradient id="paint2_linear" x1="323.205" y1="785.242" x2="-97.6164" y2="56.3589" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="white" stopOpacity="0"></stop>
                        <stop offset="1" stopColor="#377dff"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResumePage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "resume", "plans", "simulator-websites", "categories", "tools", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { services: { in: ["resume"] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {services: { in: ["resume"] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "resume"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        server {
            month_price
            year_price
        }
        custom {
          pages {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          sheets {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          plugins {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              page_price
              sheet_price
              plugin_price
              translation_price
          }
          delivered {
              active
              install
              page
              sheet
              plugin
              language {
                install
                page
                sheet
                plugin
              }
              launch
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
  }
`;
